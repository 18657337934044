export const BubblesTogetherImg = require('./project-images/bubbles-together.png');
export const BubblifyImg = require('./project-images/bubblify.png');
export const DebugDuckImg = require('./project-images/debug-duck.png');
export const PuzzleGameImg = require('./project-images/puzzle-game.PNG');
export const ColorGameImg = require('./project-images/color-game.png');
export const TodoListImg = require('./project-images/todo-list.png');
export const PongImg = require('./project-images/pong.png');
export const GameOfLifeImg = require('./project-images/game-of-life.png');
export const WebsiteImg = require('./project-images/website.png');
export const FractalTreeImg = require('./project-images/fractaltree.png');
export const SpiralOrbitImg = require('./project-images/spiral-orbit.png');
export const TankmaniaImg = require('./project-images/tankmania.png');
export const DanielProfileImg = require('./daniel-profile.jpg');